// تعريف متغير لتخزين نوع الفلترة الحالي
let current_filter = null;

// دالة لإضافة أزرار الفلترة ديناميكيًا
const addNotificationFilters = () => {
    const filterContainer = document.createElement('div');
    filterContainer.className = 'notification-custom-filters mb-4';
    
    // إنشاء زر "All"
    const btnAll = document.createElement('button');
    btnAll.id = 'filter_all';
    btnAll.className = 'btn btn-secondary active notification-filter-btn-all';
    btnAll.innerText = 'All';
    
    // إنشاء زر "Today"
    const btnToday = document.createElement('button');
    btnToday.id = 'filter_today';
    btnToday.className = 'btn btn-primary notification-filter-btn-today';
    btnToday.innerText = 'Today';
    
    // إنشاء زر "Last Week"
    const btnLastWeek = document.createElement('button');
    btnLastWeek.id = 'filter_last_week';
    btnLastWeek.className = 'btn btn-primary notification-filter-btn-last-week';
    btnLastWeek.innerText = 'Last Week';
    
    // إضافة الأزرار إلى الحاوية
    filterContainer.appendChild(btnAll);
    filterContainer.appendChild(btnToday);
    filterContainer.appendChild(btnLastWeek);
    
    // تحديد مكان إضافة الحاوية في DOM
    const notificationList = document.getElementById('notification_list');
    if (notificationList) {
        notificationList.parentNode.insertBefore(filterContainer, notificationList);
    } else {
        document.body.appendChild(filterContainer);
    }
};

// دالة لتحديث الفئات النشطة للأزرار
const updateActiveFilterButton = (activeButtonId) => {
    const buttons = document.querySelectorAll('.notification-custom-filters .btn');
    buttons.forEach(btn => {
        if (btn.id === activeButtonId) {
            btn.classList.remove('btn-secondary', 'btn-primary');
            btn.classList.add('btn-primary', 'active');
        } else {
            btn.classList.remove('btn-primary', 'active');
            btn.classList.add('btn-secondary');
        }
    });
};

// إضافة مستمعات للأزرار بعد إضافتها إلى DOM
const addFilterEventListeners = () => {
    $(document).on("click", "#filter_all", (e) => {
        current_filter = null;
        updateActiveFilterButton('filter_all');
        load_notification();
    });

    $(document).on("click", "#filter_today", (e) => {
        current_filter = "today";
        updateActiveFilterButton('filter_today');
        load_notification();
    });

    $(document).on("click", "#filter_last_week", (e) => {
        current_filter = "last_week";
        updateActiveFilterButton('filter_last_week');
        load_notification();
    });
};

// دالة لتحميل الإشعارات حسب الفلاتر
const load_notification = () => {
    console.log(`Loading notifications with filter: ${current_filter}`);
    frappe.call({
        method: "techstation_theme.events.notification_log.get_notification_logs",
        args: {
            filter_type: current_filter,
            unread_only: true
        },
        callback(r) {
            if (r.message.error) {
                $("#notification_list").html(`<div class="text-danger">${r.message.error}</div>`);
                console.error(`Error fetching notifications: ${r.message.error}`);
                return;
            }

            const notification_logs = r.message.notification_logs;
            const user_info = r.message.user_info;

            console.log(`Number of notifications fetched: ${notification_logs.length}`);

            $("#notification_list").html(`
                <div class="d-flex align-items-center justify-content-center" style="height: 100vh;">
                    <div class="loader"></div>
                </div>
            `);

            if (notification_logs.length === 0) {
                $("#notification_list").html(`<div class="text-center text-muted">No notifications found.</div>`);
                return;
            }

            let notification_html = "";
            for (const notification of notification_logs) {
                const fromUser = user_info[notification.from_user] || {};
                const user_avatar = frappe.avatar(notification.from_user, 'w-100 h-100');

                notification_html += `
                    <a class="flex items-center rounded-xl px-3 py-2.5 hover:bg-slate-100/80 notification-filter-btn mark_as_read"
                        data-docname="${notification.name}" data-document_type="${notification.document_type}" 
                        data-document_name="${notification.document_name}" href="javascript:;">
                        <div>
                            <div class="image-fit h-11 w-11 overflow-hidden rounded-full border-2 border-slate-200/70">
                            ${user_avatar}
                            </div>
                        </div>
                        <div class="sm:ml-5">
                            <div class="font-medium">${notification.subject}</div>
                            <div class="mt-0.5 text-slate-500">
                                ${notification.email_content}
                            </div>
                            <div class="mt-1.5 text-xs text-slate-500">
                            ${frappe.datetime.str_to_user(notification.creation)}
                            </div>
                        </div>
                        ${notification.read ? '' : '<div class="ml-auto h-2 w-2 flex-none rounded-full border border-primary/40 bg-primary/40"></div>'}
                    </a>
                `;
            }

            $("#notification_list").html(notification_html);
        }
    });
};

// عند الضغط على "Mark all as read"
$(document).on("click", "#mark_all_as_read", (e) => {
    frappe.call({
        method: "techstation_theme.events.notification_log.mark_all_as_read",
        callback(r) {
            if (r.message.success) {
                // تحديث جميع الإشعارات وجعلها مقروءة
                $("#notification_list").empty().html(`<div class="text-center text-muted">All notifications are now marked as read.</div>`);
            } else {
                console.error("Error marking all notifications as read:", r.message.error);
            }
        }
    });
});

// عند الضغط على إشعار فردي
$(document).on("click", ".mark_as_read", (e) => {
    let docname = $(e.currentTarget).data("docname");
    let document_type = $(e.currentTarget).data("document_type");
    let document_name = $(e.currentTarget).data("document_name");
    
    frappe.call({
        method: "techstation_theme.events.notification_log.mark_as_read",
        args: {
            docname: docname  // إرسال معرف الإشعار فقط
        },
        callback(r) {
            if (r.message.success) {
                // إخفاء الإشعار بعد تحديثه
                $(e.currentTarget).closest('.mark_as_read').remove();

                if (document_type && document_name) {
                    frappe.set_route("Form", document_type, document_name);
                }
            } else {
                console.error("Error marking notification as read:", r.message.error);
            }
        }
    });
});

// تحميل الإشعارات عند تحميل الصفحة لأول مرة وإضافة الفلاتر
$(document).ready(() => {
    addNotificationFilters();      // إضافة أزرار الفلترة ديناميكيًا
    addFilterEventListeners();    // إضافة مستمعات للأزرار
    load_notification();          // تحميل الإشعارات بشكل افتراضي
});
